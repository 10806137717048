import { TState } from 'redux/config/root-types'
import { call, select } from 'redux-saga/effects'
import DateConverter from 'utils/helpers/date'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AxiosRequestConfig } from 'axios'

const { download } = RequestMethods()

export default function* SagaWorker_GetDetailedReportXlsx() {
  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds
  const getFilter = (state: TState) => state.report.filter
  const getSelectedStartParkingIds = (state: TState) => state.report.invoicesFilter.startParkings
  const getSelectedFinishParkingIds = (state: TState) => state.report.invoicesFilter.finishParkings
  const getSelectedInvoiceType = (state: TState) => state.report.invoicesFilter.type

  //------------------------- Getting request params ---------------------------//
  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)
  const startParking: ReturnType<typeof getSelectedStartParkingIds> = yield select(getSelectedStartParkingIds)
  const finishParking: ReturnType<typeof getSelectedFinishParkingIds> = yield select(getSelectedFinishParkingIds)
  const invoiceType: ReturnType<typeof getSelectedInvoiceType> = yield select(getSelectedInvoiceType)

  const formData = {
    organization,
    // ...filter,
    fromDate: filter.fromDate ? DateConverter.toISOString(DateConverter.toStartOfDay(filter.fromDate)) : undefined,
    toDate: filter.toDate ? DateConverter.toISOString(DateConverter.toEndOfDay(filter.toDate)) : undefined,
    startParking,
    finishParking,
    type: invoiceType,
  }

  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig

  try {
    //------------------------- Getting report ---------------------------//
    yield call(async () => download('/trailers/report/xlsx/details', formData, config, 'detailed_report.xlsx'))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
