import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { Actions_report } from 'redux/modules/report/Actions'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { IDetailedReport, TDetailedReport } from 'redux/modules/report/TReducer'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import TableFilterReduxAdapter from 'utils/helpers/table'

const { get } = RequestMethods()

export default function* SagaWorker_GetUserReport({ payload }: PayloadAction<{ userId: string }>) {
  yield put(Actions_report.store.showLoader())

  const getInvoicesFilter = (state: TState) => state.report.invoicesFilter
  const getInvoicesData = (state: TState) => state.report.detailedReport?.rents || []

  //------------------------- Getting request params ---------------------------//
  const invoicesFilter: ReturnType<typeof getInvoicesFilter> = yield select(getInvoicesFilter)
  const oldInvoicesData: ReturnType<typeof getInvoicesData> = yield select(getInvoicesData)

  const formData = {
    limit: invoicesFilter.limit,
    offset: invoicesFilter.offset,
    orderBy: 'DESC',
    sorting: 'invoiceDate',
    userId: payload.userId,
  }

  try {
    //------------------------- Getting report ---------------------------//
    const result: TStoreTemplate<TDetailedReport> = yield call(
      async () => await get<TStoreTemplate<TDetailedReport>>('/trailers/report/details', formData),
    )

    const newReportData = result.data?.rents || []
    const isNeedConcatData = invoicesFilter && invoicesFilter.offset! > 0 ? true : false
    const data = isNeedConcatData
      ? {
          ...(result.data as TDetailedReport),
          rents: TableFilterReduxAdapter.concatData<IDetailedReport>(newReportData, oldInvoicesData),
        }
      : result.data
    const isAllInvoicesDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newReportData, invoicesFilter)

    yield put(
      Actions_report.store.saveDetailedReportRents({
        rents: data!.rents,
        isAllInvoicesDataLoaded,
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_report.store.hideLoader())
}
