enum StoreActions {
  IS_LOADER_SHOW = 'REPORT/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'REPORT/IS_LOADER_HIDE',
  SAVE_REPORT = 'REPORT/SAVE_REPORT',
  CHANGE_FILTER = 'REPORT/CHANGE_FILTER',
  INCREASE_FILTER_OFFSET = 'REPORT/INCREASE_FILTER_OFFSET',
  INCREASE_USER_REPORT_FILTER_OFFSET = 'REPORT/INCREASE_USER_REPORT_FILTER_OFFSET',
  INCREASE_INVOICE_FILTER_OFFSET = 'REPORT/INCREASE_INVOICE_FILTER_OFFSET',
  SET_INVOICE_NUMBER_IN_INVOICE_FILTER = 'REPORT/SET_INVOICE_NUMBER_IN_INVOICE_FILTER',
  SET_INVOICE_FILTER = 'REPORT/SET_INVOICE_FILTER',
  RESET_INVOICE_FILTER = 'REPORT/RESET_INVOICE_FILTER',
  OPEN_DETAILED_TABLE = 'REPORT/OPEN_DETAILED_TABLE',
  CLOSE_DETAILED_TABLE = 'REPORT/CLOSE_DETAILED_TABLE',
  CHOOSEN_TURN_OVER = 'REPORT/CHOOSEN_TURN_OVER',
  CHANGED_TURN_OVER = 'REPORT/CHANGED_TURN_OVER',
  SAVE_DETAILED_REPORT_RENTS = 'REPORT/SAVE_DETAILED_REPORT_RENTS',
  SAVE_DETAILED_REPORT_TOTAL_COST = 'REPORT/SAVE_DETAILED_REPORT_TOTAL_COST',
  RESET = 'REPORT/RESET',
  SAVE_HEATMAP_REPORTS = 'REPORT/SAVE_HEATMAP_REPORTS',
}

enum SagaEvents {
  GET_REPORT = 'REPORT/GET_REPORT',
  GET_DETAILED_REPORT = 'REPORT/GET_DETAILED_REPORT',
  GET_USER_REPORT = 'REPORT/GET_USER_REPORT',
  GET_DETAILED_REPORT_TOTAL_COST = 'REPORT/GET_DETAILED_REPORT_TOTAL_COST',
  LOAD_PAGE = 'REPORT/LOAD_PAGE',
  LOAD_XLSX = 'REPORT/LOAD_XLSX',
  LOAD_DETAILED_XLSX = 'REPORT/LOAD_DETAILED_XLSX',
  LOAD_DETAILED_USER_REPORT_XLSX = 'REPORT/LOAD_DETAILED_USER_REPORT_XLSX',
  LOAD_CSV = 'REPORT/LOAD_CSV',
}

export const ActionTypes_Report = {
  SagaEvents,
  StoreActions,
}
