import { call } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AxiosRequestConfig } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'

const { download } = RequestMethods()

export default function* SagaWorker_GetUserReportXlsx({ payload }: PayloadAction<{ userId: string }>) {
  //------------------------- Getting request params ---------------------------//

  const formData = {
    userId: payload.userId,
  }

  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig

  try {
    //------------------------- Getting report ---------------------------//
    yield call(async () => download('/trailers/report/xlsx/details', formData, config, 'detailed_report_user.xlsx'))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
