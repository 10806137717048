import { TState } from 'redux/config/root-types'
import { call, select } from 'redux-saga/effects'
import DateConverter from 'utils/helpers/date'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AxiosRequestConfig } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'

const { download } = RequestMethods()

export default function* SagaWorker_GetReportCsv({ payload }: PayloadAction<{ type: 'report' }>) {
  const getFilter = (state: TState) => state.report.filter
  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds

  //------------------------- Getting request params ---------------------------//
  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)

  const formData = {
    organization,
    fromDate: filter.fromDate ? DateConverter.toISOString(DateConverter.toStartOfDay(filter.fromDate)) : undefined,
    toDate: filter.toDate ? DateConverter.toISOString(DateConverter.toEndOfDay(filter.toDate)) : undefined,
    type: payload?.type ? payload.type : undefined,
  }

  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig

  try {
    //------------------------- Getting report ---------------------------//
    yield call(async () => download('/rents/invoices/report/csv', formData, config, 'report.csv'))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
