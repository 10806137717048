import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Radio } from '@mui/material'
import { useReportsDetailedInvoiceTypeFilter } from './ReportsDetailedInvoiceTypeFilter-ViewModel'

export const ReportsDetailedInvoiceTypeFilter: FC = () => {
  const vm = useReportsDetailedInvoiceTypeFilter()
  const { closeModal, onSubmit, data, checkedValue, handleItem, t, classes } = vm

  return (
    <Shared.Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Shared.CardTitle title={t('title')} />
        </Grid>

        <Grid item xs={12}>
          <List className={classes.list}>
            {data.map((status) => {
              const labelId = `checkbox-list-label-${status.id}`
              return (
                <ListItem key={status.id} role={undefined} dense button onClick={handleItem(status.value)}>
                  <ListItemIcon>
                    <Radio
                      edge="start"
                      checked={status.value === checkedValue}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      color={'primary'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={status.name || '-'}
                    classes={{ root: classes.listItemText }}
                    disableTypography
                  />
                </ListItem>
              )
            })}
          </List>
        </Grid>

        <Grid item xs={12}>
          <Shared.Button className={classes.cancel} color={'secondary'} title={t('cancel')} onClick={closeModal} />
          <Shared.Button title={t('submit')} onClick={onSubmit} />
        </Grid>
      </Grid>
    </Shared.Card>
  )
}
