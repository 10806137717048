import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { makeStyles } from 'tss-react/mui'
import { Actions_Bookings } from 'redux/modules/bookings/Actions'

const data = [
  { id: 0, value: undefined, name: 'All' },
  { id: 1, value: true, name: 'Completed rent' },
  { id: 2, value: false, name: 'Not completed rent' },
]

export const useBookingsCompletedFilter = () => {
  const { t } = useTranslation(I18nModal.bookingCompletedFilter)
  const dispatch = useDispatch()
  const { classes } = useStyles()

  const filters = useSelector((state: TState) => state.bookings.filters)

  const [checkedValue, setCheckedValue] = useState<boolean | undefined>(filters.completed)

  const handleItem = (value: boolean | undefined) => () => {
    setCheckedValue(value)
  }

  const onSubmit = () => {
    dispatch(Actions_Bookings.store.changeFilter({ ...filters, completed: checkedValue, offset: 0 }))
    dispatch(Actions_Modals.store.hideModal())
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    classes,
    closeModal,
    onSubmit,
    data: data,
    checkedValue,
    handleItem,
  }
}

const useStyles = makeStyles()((theme) => ({
  card: {
    width: 375,
    overflowY: 'auto',
    maxHeight: '100%',
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
  },
  listItemText: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  cancel: {
    margin: '0.875em 0',
  },
}))
