enum StoreActions {
  IS_LOADER_SHOW = 'CURRENT_TRAILER/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CURRENT_TRAILER/IS_LOADER_HIDE',
  SAVE_CURRENT_TRAILER = 'CURRENT_TRAILER/SAVE_CURRENT_TRAILER',
  SELECT_TRAILER_INFO = 'CURRENT_TRAILER/SELECT_TRAILER_INFO',
  CLEAR_SELECTED_TRAILER_INFO = 'CURRENT_TRAILER/CLEAR_SELECTED_TRAILER_INFO',
}

enum SagaEvents {
  LOAD_PAGE = 'CURRENT_TRAILER/LOAD_PAGE',
  GET_CURRENT_TRAILER = 'CURRENT_TRAILER/GET_CURRENT_TRAILER',
  CREATE_TRAILER = 'CURRENT_TRAILER/CREATE_TRAILER',
  UPDATE_TRAILER = 'CURRENT_TRAILER/UPDATE_TRAILER',
  DELETE_TRAILER = 'CURRENT_TRAILER/DELETE_TRAILER',
  SHOW_ON_MAP = 'CURRENT_TRAILER/SHOW_ON_MAP',
  CREATE_SPECIAL_RENT = 'CREATE_SPECIAL_RENT',
}

export const ActionTypes_CurrentTrailer = {
  SagaEvents,
  StoreActions,
}
