import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import DateConverter from 'utils/helpers/date'
import { useCallback, useEffect, useMemo } from 'react'
import { TColumns, TTableFilter } from 'ui/shared/Table/Table'
import { IDetailedReport, TInvoicesFilter } from 'redux/modules/report/TReducer'
import { Actions_report } from 'redux/modules/report/Actions'
import { useAuthRights } from 'ui/hooks/useAuthRights'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import TableFilterReduxAdapter from 'utils/helpers/table'
import { TTableLinkCellProps } from 'ui/shared/TableLinkCell/TableLinkCell'

export interface IInvoicesTable {
  trailerType: string
  plate: number | string
  insuranceCoverage: number
  insuranceTotalPrice: number
  totalCost?: string | number
  totalRentTime: string
  invoceNumber?: string
  invoiceType: string
  promocode: string
  rentStart: string
  invoiceNumber: TTableLinkCellProps
  invoiceDate: string
}

const columns: TColumns = [
  {
    label: 'Invoice number',
    dataKey: 'invoiceNumber',
    width: 140,
    minWidth: 140,
    disableSort: true,
    componentType: 'link',
  },
  {
    label: 'Invoice type',
    dataKey: 'invoiceType',
    width: 150,
    minWidth: 150,
    disableSort: true,
  },
  {
    label: 'Invoice date',
    dataKey: 'invoiceDate',
    width: 200,
    minWidth: 150,
    disableSort: true,
  },
  {
    label: 'Trailer type',
    dataKey: 'trailerType',
    width: 130,
    minWidth: 100,
    disableSort: true,
  },
  {
    label: 'Trailer number',
    dataKey: 'plate',
    width: 170,
    minWidth: 100,
    disableSort: true,
  },
  {
    label: 'Insurance coverage',
    dataKey: 'insuranceCoverage',
    disableSort: true,
    width: 130,
    minWidth: 100,
  },
  {
    label: 'Insurance Total Price',
    dataKey: 'insuranceTotalPrice',
    disableSort: true,
    width: 130,
    minWidth: 100,
  },
  {
    label: 'Trailer cost',
    dataKey: 'totalCost',
    width: 130,
    minWidth: 100,
    disableSort: true,
  },
  {
    label: 'Start of lease',
    dataKey: 'rentStart',
    width: 180,
    minWidth: 150,
    disableSort: true,
  },
  {
    label: 'Action code',
    dataKey: 'promocode',
    width: 150,
    minWidth: 100,
    disableSort: true,
  },
  {
    label: 'Total rent time',
    dataKey: 'totalRentTime',
    disableSort: true,
    width: 200,
    minWidth: 150,
  },
]

export const useInvoicesTable = (userId: string) => {
  const { trailerTypes, detailedReport, filter, invoiceFilter, isAllInvoicesDataLoaded, isLoading } = useSelector(
    ({ organizations, trailerTypes, report, parkings }: TState) => ({
      organizations: organizations.data?.organizations || [],
      parkings: parkings.allParkings,
      trailerTypes: trailerTypes.data || [],
      detailedReport: report.detailedReport?.rents || [],
      filter: report.filter,
      invoiceFilter: report.invoicesFilter,
      isLoading: report.isLoading,
      isAllInvoicesDataLoaded: report.isAllInvoicesDataLoaded,
    }),
    shallowEqual,
  )
  const { canReportRentHistoryCostData, canReportTotalTime } = useAuthRights()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(Actions_report.store.resetInvoiceFilter())
    }
  }, [])

  useEffect(
    function loadReport() {
      dispatch(Actions_report.saga.getUserReport({ userId }))
    },
    [dispatch, invoiceFilter.orderBy, invoiceFilter.sorting, invoiceFilter.limit, invoiceFilter.offset],
  )

  const onExcelClick = () => {
    dispatch(Actions_report.saga.loadDetailedUserReportXlsx({ userId }))
  }

  const getTrailerTypeName = useCallback(
    (typeId: number): string => trailerTypes.find(({ type: { id } }) => id === typeId)?.type.name || '',
    [trailerTypes],
  )

  const onLoadMore = useCallback(async () => {
    const updatedFilter = TableFilterReduxAdapter.increaseOffset<TInvoicesFilter>(invoiceFilter, detailedReport.length)

    dispatch(Actions_report.store.increaseUserReportFilterOffset(updatedFilter))
  }, [dispatch, invoiceFilter, detailedReport])

  const data: IInvoicesTable[] = useMemo(
    () =>
      detailedReport.map((data: IDetailedReport): IInvoicesTable => {
        const downloadBill = () => dispatch(Actions_CurrentUser.saga.getInvoice({ id: data.invoiceId }))
        return {
          trailerType: getTrailerTypeName(data.trailerTypeId),
          plate: data.trailerPlate ? data.trailerPlate : '',
          insuranceCoverage: data.insurancePlan ? data.insurancePlan.coverage : 0,
          insuranceTotalPrice: data.insuranceTotalCost ? data.insuranceTotalCost : 0,
          totalCost: data.totalCost ? `${data.totalCost} €` : '',
          invoiceNumber: { text: data.invoiceNumber.toString(), onClick: downloadBill },
          invoiceType: data.invoiceType || '',
          invoiceDate: DateConverter.formatDate(data.invoiceDate) || '',
          rentStart: DateConverter.formatDate(data.startDate) || '',
          promocode: data.promocode,
          totalRentTime: data.totalTime ? DateConverter.minutesToDDHHMM(data.totalTime) : '',
        }
      }) || [],
    [detailedReport, getTrailerTypeName],
  )

  const onChangeFilter = useCallback(
    (newFilter: TTableFilter) => {
      const updatedFilter = { ...invoiceFilter, ...newFilter, offset: 0 }
      dispatch(Actions_report.store.setInvoiceFilter(updatedFilter))
    },
    [dispatch, invoiceFilter],
  )

  const updatedColumns = useMemo(
    () => toUpdatedColumns(columns, canReportRentHistoryCostData, canReportTotalTime),
    [columns, canReportRentHistoryCostData, canReportTotalTime],
  )

  return {
    columns: updatedColumns,
    data,
    onExcelClick,
    filter,
    isLoading,
    onLoadMore,
    onChangeFilter,
    isAllDataLoaded: isAllInvoicesDataLoaded,
    isSortByServer: true,
  }
}

const toUpdatedColumns = (columns: TColumns, canViewTotalCost: boolean, canViewTotalTime: boolean) => {
  const updatedColumns = columns.filter((column) => {
    switch (column.dataKey) {
      case 'totalCost':
        return canViewTotalCost
      case 'totalRentTime':
        return canViewTotalTime
      default:
        return true
    }
  })

  return updatedColumns
}
