import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_CurrentTrailer } from './TActions'
import { TCurrentTrailer, TSelectedTrailerInfoPayload } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_CreateTrailer } from 'redux/sagas/events/trailer/SagaWorker_CreateTrailer'
import { ISagaWorker_UpdateTrailer } from 'redux/sagas/events/trailer/SagaWorker_UpdateTrailer'
import { ISagaWorker_TrailerDetails } from 'redux/sagas/pages/SagaPage_TrailerDetails'
import { ISagaWorker_DeleteTrailer } from 'redux/sagas/events/trailer/SagaWorker_DeleteTrailer'
import { ISagaWorker_GetCurrentTrailer } from 'redux/sagas/events/trailer/SagaWorker_GetCurrentTrailer'
import { ISagaWorker_ShowOnMap } from 'redux/sagas/events/trailer/SagaWorker_ShowOnMap'
import { ISagaWorker_CreateSpecialRent } from 'redux/sagas/events/trailer/SagaWorker_CreateSpecialRent'

const { SagaEvents, StoreActions } = ActionTypes_CurrentTrailer

export const Actions_CurrentTrailer = {
  saga: {
    loadPage: createAction<ISagaWorker_TrailerDetails>(SagaEvents.LOAD_PAGE),
    getCurrentTrailer: createAction<ISagaWorker_GetCurrentTrailer>(SagaEvents.GET_CURRENT_TRAILER),
    createTrailer: createAction<ISagaWorker_CreateTrailer>(SagaEvents.CREATE_TRAILER),
    createSpecialRent: createAction<ISagaWorker_CreateSpecialRent>(SagaEvents.CREATE_SPECIAL_RENT),
    updateTrailer: createAction<ISagaWorker_UpdateTrailer>(SagaEvents.UPDATE_TRAILER),
    deleteTrailer: createAction<ISagaWorker_DeleteTrailer>(SagaEvents.DELETE_TRAILER),
    showOnMap: createAction<ISagaWorker_ShowOnMap>(SagaEvents.SHOW_ON_MAP),
  },
  store: {
    changeSelectedTrailerInfo: createAction<TSelectedTrailerInfoPayload>(StoreActions.SELECT_TRAILER_INFO),
    clearSelectedTrailerInfo: createAction(StoreActions.CLEAR_SELECTED_TRAILER_INFO),
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveCurrentTrailer: createAction<TStoreTemplate<TCurrentTrailer>>(StoreActions.SAVE_CURRENT_TRAILER),
  },
}
