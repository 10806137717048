import React, { FC, useState } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { makeStyles } from 'tss-react/mui'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CalendarHeader } from 'ui/shared/RangeDatePicker/RangeCalendarModal/CalendarHeader/CalendarHeader'
import DatePicker from 'react-datepicker'
import DateConverter from 'utils/helpers/date'
import { I18nPage } from 'utils/i18n/i18nPage'
import { useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'

const DAY_WIDTH = 50

type TModalProps = {
  trailerId: number
}

export const AddSpecialRent: FC = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation(I18nPage.trailerDetails)
  const { classes, cx } = useStyles()

  const modalProps = useSelector(({ modals }: TState) => modals.data as TModalProps)

  const [startDate, setStartDate] = useState<Date | null | undefined>(null)
  const [endDate, setEndDate] = useState<Date | null | undefined>(null)

  const onChange = (dates: (Date | null)[]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const onSubmit = () => {
    if (startDate && endDate) {
      dispatch(Actions_CurrentTrailer.saga.createSpecialRent({ startDate, endDate, trailerId: modalProps.trailerId }))
    }
  }

  return (
    <Shared.Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Shared.CardTitle title={t('addSpecialRent')} />
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              className={classes.datePicker}
              calendarClassName={classes.calendar}
              dayClassName={(date) => {
                return cx(classes.day, {
                  [classes.edgeSelectedDays]:
                    DateConverter.isSameDay(date, startDate) || DateConverter.isSameDay(date, endDate),
                })
              }}
              renderCustomHeader={CalendarHeader}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Shared.Button className={classes.cancel} color={'secondary'} title={t('cancel')} onClick={closeModal} />
          <Shared.Button title={t('add')} onClick={onSubmit} />
        </Grid>
      </Grid>
    </Shared.Card>
  )
}

const useStyles = makeStyles()((theme) => ({
  card: {
    width: 400,
    overflowY: 'auto',
    maxHeight: '100%',
    '&& .react-datepicker': {
      border: 'none',
      borderRadius: 0,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
  },
  listItemText: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  cancel: {
    margin: '0.875em 0',
  },

  datePicker: {},
  calendar: {
    '&& .react-datepicker__month': {
      margin: 0,
    },

    '&& .react-datepicker__header': {
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      padding: 0,
    },

    '&& .react-datepicker__day-names': {
      display: 'none',
    },

    '&& .react-datepicker__day': {
      borderRadius: 0,
      width: DAY_WIDTH,
      margin: 0,
      color: theme.palette.text.primary,
      lineHeight: '38px',
    },
  },
  day: {
    '&&:hover': {
      borderRadius: 0,
      background: '#e4e7e7',
    },

    '&&:focus-visible': {
      outlineColor: theme.palette.text.secondary,
      outlineWidth: 2,
      outlineStyle: 'solid',
      borderRadius: '0',
    },

    '&&.react-datepicker__day--in-selecting-range': {
      background: '#fff2dc',
    },

    '&&.react-datepicker__day--selected': {
      background: theme.palette.primary.main,
    },

    '&&.react-datepicker__day--in-range': {
      background: '#fff2dc',

      '&&:hover': {
        background: theme.palette.primary.main,
      },
    },
  },

  edgeSelectedDays: {
    '&&.react-datepicker__day--in-range': {
      background: theme.palette.primary.main,
    },
  },
}))
