import { TState } from 'redux/config/root-types'
import { call, select } from 'redux-saga/effects'
import DateConverter from 'utils/helpers/date'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AxiosRequestConfig } from 'axios'

const { download } = RequestMethods()

export default function* SagaWorker_GetBookingsXlsx() {
  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds
  const getFilter = (state: TState) => state.bookings.filters

  //------------------------- Getting request params ---------------------------//
  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)

  const formData = {
    organization,
    fromDate: filter.fromDate ? DateConverter.toISOString(DateConverter.toStartOfDay(filter.fromDate)) : undefined,
    toDate: filter.toDate ? DateConverter.toISOString(DateConverter.toEndOfDay(filter.toDate)) : undefined,
    searchStr: filter.searchStr ? filter.searchStr : undefined,
    cancelled: filter.cancelled,
    completed: filter.completed,
  }

  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig

  try {
    //------------------------- Getting report ---------------------------//
    yield call(async () => download('/bookings/forFuture/report/xlsx', formData, config, 'bookings.xlsx'))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
