enum StoreActions {
  IS_LOADER_SHOW = 'BOOKINGS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'BOOKINGS/IS_LOADER_HIDE',
  SAVE_BOOKINGS = 'BOOKINGS/SAVE_BOOKINGS',
  FILTER_BOOKINGS = 'BOOKINGS/FILTER_BOOKINGS',
  CHANGE_FILTER = 'BOOKINGS/CHANGE_FILTER',
  INCREASE_FILTER_OFFSET = 'BOOKINGS/INCREASE_FILTER_OFFSET',
  RESET = 'BOOKINGS/RESET',
}

enum SagaEvents {
  LOAD_PAGE = 'BOOKINGS/LOAD_PAGE',
  GET_BOOKINGS = 'BOOKINGS/GET_BOOKINGS',
  GET_BOOKINGS_XLSX = 'BOOKINGS/GET_BOOKINGS_XLSX',
}

export const ActionTypes_Bookings = {
  SagaEvents,
  StoreActions,
}
