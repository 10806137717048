import { call, put } from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import { RequestMethods } from 'utils/axios/methods'
import { AxiosRequestConfig } from 'axios'

const { download } = RequestMethods()

export interface ISagaWorker_GetInvoice {
  id: number
}

export default function* SagaWorker_GetInvoice(action: PayloadAction<ISagaWorker_GetInvoice>) {
  yield put(Actions_CurrentUser.store.showLoader())

  try {
    const id = action.payload.id

    const config = {
      responseType: 'blob',
    } as AxiosRequestConfig

    yield call(async () => download(`/rents/invoices/${id}/pdf`, undefined, config))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentUser.store.hideLoader())
  }
}
