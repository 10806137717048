import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { makeStyles } from 'tss-react/mui'
import { Actions_report } from 'redux/modules/report/Actions'

const data = [
  { id: 0, value: undefined, name: 'All' },
  { id: 1, value: 'default', name: 'Default rent' },
  { id: 2, value: 'booking', name: 'Booking' },
  { id: 3, value: 'additional', name: 'Additional' },
  { id: 4, value: 'refund', name: 'Refund' },
  { id: 5, value: 'oneWay', name: 'One-Way' },
]

export const useReportsDetailedInvoiceTypeFilter = () => {
  const { t } = useTranslation(I18nModal.reportsDetailedInvoiceTypeFilter)
  const dispatch = useDispatch()
  const { classes } = useStyles()

  const invoicesFilter = useSelector((state: TState) => state.report.invoicesFilter)

  const [checkedValue, setCheckedValue] = useState<string | undefined>(invoicesFilter.type)

  const handleItem = (value: string | undefined) => () => {
    setCheckedValue(value)
  }

  const onSubmit = () => {
    const updatedFilter = { ...invoicesFilter, type: checkedValue, offset: 0 }
    dispatch(Actions_report.store.setInvoiceFilter(updatedFilter))
    dispatch(Actions_Modals.store.hideModal())
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    classes,
    closeModal,
    onSubmit,
    data: data,
    checkedValue,
    handleItem,
  }
}

const useStyles = makeStyles()((theme) => ({
  card: {
    width: 375,
    overflowY: 'auto',
    maxHeight: '100%',
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
  },
  listItemText: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  cancel: {
    margin: '0.875em 0',
  },
}))
