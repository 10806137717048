import React, { FC } from 'react'
import { Shared } from 'ui/shared'

import { IInvoicesTable, useInvoicesTable } from './InvoicesTable-ViewModel'
type Props = {
  userId: string
}
const InvoicesTable: FC<Props> = ({ userId }) => {
  const vm = useInvoicesTable(userId)
  return (
    <div style={{ margin: 0 }}>
      <Shared.Table<IInvoicesTable> {...vm} headerStyle={{ fontSize: '1rem' }} height={'80vh'} />
    </div>
  )
}

export default React.memo(InvoicesTable)
