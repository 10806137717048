import { createReducer } from '@reduxjs/toolkit'
import { Actions_Bookings } from './Actions'
import { IStateBookings } from './TReducer'

const initialState: IStateBookings = {
  isLoading: false,
  data: null,
  responseInfo: null,
  isAllDataLoaded: false,
  filters: {
    limit: 30,
    offset: 0,
    fromDate: null,
    toDate: null,
    searchStr: '',
  },
}

const ReducerTrackerCodes = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Bookings.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Bookings.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Bookings.store.saveBookings, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Bookings.store.changeFilter, (state, action) => {
      state.filters = action.payload
    })
    .addCase(Actions_Bookings.store.increaseFilterOffset, (state, action) => {
      state.filters = action.payload
    })
    .addCase(Actions_Bookings.store.reset, () => {
      return initialState
    })
})

export default ReducerTrackerCodes
