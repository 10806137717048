import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Bookings } from './TActions'
import { TBookingFilters, TFutureBooking } from './TReducer'
import { TStoreTemplateWithFilter } from 'redux/config/root-types'

const { StoreActions, SagaEvents } = ActionTypes_Bookings

export const Actions_Bookings = {
  saga: {
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    getBookings: createAction(SagaEvents.GET_BOOKINGS),
    getBookingsXlsx: createAction(SagaEvents.GET_BOOKINGS_XLSX),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveBookings: createAction<TStoreTemplateWithFilter<TFutureBooking[]>>(StoreActions.SAVE_BOOKINGS),
    changeFilter: createAction<TBookingFilters>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TBookingFilters>(StoreActions.INCREASE_FILTER_OFFSET),
    reset: createAction(StoreActions.RESET),
  },
}
