import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { makeStyles } from 'tss-react/mui'

export const useUserListInvoices = () => {
  const { t } = useTranslation(I18nModal.userListInvoices)
  const dispatch = useDispatch()
  const { classes } = useStyles()

  const userId = useSelector(({ modals }: TState) => modals.data.id)

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    classes,
    closeModal,
    userId,
  }
}

const useStyles = makeStyles()((theme) => ({
  card: {
    width: '90vw',
    overflowY: 'auto',
    maxHeight: '100%',
    height: '90vh',
  },
}))
