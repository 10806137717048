import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { useUserListInvoices } from './UserListInvoices-ViewModel'
import { IconButton } from '@mui/material'
import { Controls } from 'assets/images/Controls'
import ReportsDetailedTable from 'ui/pages/Reports/components/ReportsDetailedTable/ReportsDetailedTable'
import InvoicesTable from './components/InvoicesTable/InvoicesTable'

export const UserListInvoices: FC = () => {
  const vm = useUserListInvoices()
  const { closeModal, userId, t, classes } = vm

  return (
    <Shared.Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid container spacing={1} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Shared.CardTitle title={t('title')} />
          </Grid>
          <Grid item>
            <IconButton size={'small'} onClick={closeModal}>
              <Controls.CloseDarkSmall />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <InvoicesTable userId={userId} />
        </Grid>
      </Grid>
    </Shared.Card>
  )
}
