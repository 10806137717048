import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'
import { TCurrentTrailer } from 'redux/modules/currentTrailer/TReducer'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import DateConverter from 'utils/helpers/date'

const { post } = RequestMethods()

export interface ISagaWorker_CreateSpecialRent {
  startDate: Date
  endDate: Date
  trailerId: number
}

export default function* SagaWorker_CreateSpecialRent(action: PayloadAction<ISagaWorker_CreateSpecialRent>) {
  //------------------------- Getting token and authorize ---------------------------//

  yield put(Actions_CurrentTrailer.store.showLoader())

  const data = {
    startDate: action.payload.startDate
      ? DateConverter.toISOString(DateConverter.toStartOfDay(action.payload.startDate))
      : undefined,
    endDate: action.payload.startDate
      ? DateConverter.toISOString(DateConverter.toEndOfDay(action.payload.endDate))
      : undefined,
  }

  try {
    //------------------------- Getting and saving trailer ---------------------------//
    yield call(async () => await post<TCurrentTrailer>(`/trailers/${action.payload.trailerId}/bookByAdmin`, data))

    yield put(Actions_Modals.store.hideModal())
    yield put(
      Actions_Notifications.store.setNotification({ message: 'Special rent successfully created', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentTrailer.store.hideLoader())
  }
}
